import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Switch from '@material-ui/core/Switch';
import moment from 'moment';
import axios from 'axios';
import { db } from '.././firebase';

export default class ControlLogger extends Component {
    constructor(props){
        super(props);

        this.state = {
            columns: [
                { title: 'Client', field: 'client' },
                { title: 'Email', field: 'email' },
                { title: 'Type', field: 'control_type' },
                { title: 'Player', field: 'player_name' },
                { title: 'Market', field: 'market_abbr' },
                { title: 'Team', field: 'team_name' },
                { title: 'Local Timestamp', field: 'timestamp'}
            ]
        }

        this.Title = this.Title.bind(this);
        this.pullSIQlogs = this.pullSIQlogs.bind(this);
    }

    pullSIQlogs(game_id) {
        var url = 'https://us-central1-lunar-brace-213823.cloudfunctions.net/pull_siq_control_logger?game_id=' +  game_id;
        axios.get(url)
        .then(res => {
            var siq_control_logs = res.data.data;
            var count = 0;
            var total = siq_control_logs.length;
            siq_control_logs.forEach(log => {
                log.timestamp = moment(log.timestamp).local().format('YYYY-MM-DD HH:mm:ss');
                count ++;
                if (total === count) {
                    this.setState({data: siq_control_logs});
                }
            });
        })
        .catch((error)=> {
            console.log('siq control log pulling error')
        }) 
    }

    Title(game_id) {
        return(
            <div style={{display:'flex'}}>
                <div style={{fontSize:'1.25rem'}}>
                    SIQ Control Logs
                </div>
                <div style={{marginLeft:'10px', marginTop:'6px'}}>
                    <a href={"https://us-central1-lunar-brace-213823.cloudfunctions.net/pull_siq_control_logger?game_id="+game_id+""} target="_blank">Data Detail</a>
                </div>
            </div>
        )
    }

    componentDidMount() {
        if (this.props.selectedGame && this.props.selectedGame.control_logs) {
            var siq_control_logs =  this.props.selectedGame.control_logs;
            var count = 0;
            var total = siq_control_logs.length;
            siq_control_logs.forEach(log => {
                log.timestamp = moment(log.timestamp).local().format('YYYY-MM-DD HH:mm:ss');
                count ++;
                if (total === count) {
                    this.setState({data: this.props.selectedGame.control_logs});
                }
            });
        }
        // console.log(this.props.selectedGame.control_logs);
    }

    render() {
        return (
            <div>
                <MaterialTable
                style={{margin:'10px 5px',  overflow: 'auto', backgroundColor:'#333333', color:'white'}}
                title={this.Title(this.props.game_id)}
                columns={this.state.columns}
                data={this.state.data}
                options = {
                    {
                        headerStyle:{backgroundColor:'#333333', color:'white'},
                        rowStyle: {color: 'white'},
                        search: true,
                        paging: false,
                        actionsColumnIndex: -1,
                        padding: 'dense'
                    }
                }
                actions = {
                    [
                        {
                            icon: 'refresh',
                            iconProps: {
                                color: "primary"
                            },
                            tooltip: 'Pull Lineup',
                            isFreeAction: true,
                            onClick: () => {
                                this.pullSIQlogs(this.props.game_id);
                            }
                        }
                    ]
                }
            />
            </div>
        )
    }
}
