import React, { Component } from 'react'
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import GameStatusLabel from './GameStatusLabel';
import { colorPicker, DesktopAwayScoreAbbr, DesktopHomeScoreAbbr } from '../utilities.js';
import { DesktopLeftTriangle, DesktopRightTriangle, TeamLogo } from '../utilities.js';


export default class GameWidget extends Component {
    constructor(props){
        super(props);
    
        this.state = {
        }
    }

    render() {
        var awayColor = colorPicker(this.props.game.away.abbr, this.props.sports);
        var homeColor = colorPicker(this.props.game.home.abbr, this.props.sports);
        // console.log(this.props);
        return (
            <div>
                <div className="gameWidgetBorder">
                    <div>
                        <DesktopAwayScoreAbbr style={{width: '65px'}} color={awayColor} team={this.props.game.away.abbr}/>
                        <TeamLogo color={awayColor} team={this.props.game.away.abbr} sports={this.props.sports} source="desktop" />
                    </div>
                    <div style={DesktopLeftTriangle(awayColor)}></div>
                    <div style={{width:'140px', margin:'auto', display:'flex'}}>
                            <div style={{width: '19px'}}>

                            </div>
                            <Link className="homeCenterInfo" to={`/${this.props.sports}/${moment(this.props.game.scheduled).format('YYYY-MM-DD')}/game/${this.props.game.id}`} style={{color:'black', verticalAlign:'middle'}} > 
                                <div>
                                    <div>
                                        {moment(this.props.game.scheduled).format('ddd H:mm')}
                                    </div>
                                    <div>
                                        {this.props.game.status}
                                    </div>
                                    {this.props.game.rotation_id?
                                        <div>
                                            {this.props.game.rotation_id}
                                        </div>:
                                        null
                                    }
                                    {this.props.game.sr_game_id?
                                        <div style={{fontSize:'small'}}>
                                            {this.props.game.sr_game_id}
                                        </div>:
                                        null
                                    }
                                </div>
                            </Link>
                            <div style={{width: '19px'}}>

                            </div>
                    </div>
                    <div style={DesktopRightTriangle(homeColor)}></div>
                    <div>
                        <DesktopHomeScoreAbbr style={{width: '65px'}} color={homeColor} team={this.props.game.home.abbr}/>
                        <TeamLogo color={homeColor} team={this.props.game.home.abbr} sports={this.props.sports} source="desktop" />
                    </div>
                </div>
                <GameStatusLabel sports={this.props.sports} game={this.props.game} />
            </div>
        )
    }
}
