import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Switch from '@material-ui/core/Switch';
import StarIcon from '@material-ui/icons/Star';
import { Button, Select, FormControl, MenuItem } from "@material-ui/core";
import axios from "axios";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import * as E2EASelectors from '../e2ea/e2ea_selectors.json'


export default class MLBLineupTable extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            columns: [
                { title: 'Name', field: 'name' },
                { title: 'Team', render: (row) => {
					return <div id={E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.PLAYER_TEAM_NAME}>{row.team}</div>
				    }
                },
                { title: 'SR ID', render: (row) => {
					return <div id={E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.PLAYER_SR_ID}>{row.sr_player_id}</div>
				    }
                },
                { title: 'Position', render: (row) => {
					return <div id={E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.PLAYER_POSITION}>{row.position}</div>
				    }
                },
                {
                  title: 'Batting Order',
                  field: 'battingOrder',
                //   lookup: { 1: 'Active', 0: 'Not Active' },
                  defaultSort: 'asc',
                  hidden: true
                },
            ]
        }
    }


    componentDidMount() {

    }

    render() {
        return (
            <MaterialTable
                style={{margin:'10px 5px',  overflow: 'auto', backgroundColor:'#333333', color:'white'}}
                title={this.props.title}
                columns={this.state.columns}
                data={this.props.data}
                options = {
                    {
                        headerStyle:{backgroundColor:'#333333', color:'white'},
                        rowStyle: {color: 'white'},
                        actionsColumnIndex: -1,
                        padding: 'dense',
                        pageSize: 20,
                        pageSizeOptions: [20, 30],
                    }
                }
                actions = {
                    [
                        rowData => (
                            {
                                icon: props => (
                                    <div style={{display:'flex'}} id={E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.PLAYER_STATUSES}>
                                        {this.props.shading_market!==""?
                                            <div>
                                                <Button
                                                    onClick={() => {
                                                        var newData = rowData;
                                                        var shadingBody = {
                                                            "game_id": newData.gameId,
                                                            "email": this.props.email,
                                                            "note": this.props.shading_market.split(", ")[0],
                                                            "league": this.props.selectedLeague,
                                                            "player": {
                                                                "player_id": newData.playerId,
                                                                "team_id": this.props.team_id,
                                                            },
                                                            "market_id": Number(this.props.shading_market.split(", ")[1]),
                                                            "timestamp": new Date().toISOString(),
                                                            "period": 1,
                                                            "type": this.props.shading_market.split(", ")[2],
                                                            "live": false,
                                                            "bookmaker": {
                                                                "id": 999,
                                                                "name": "SIQ"
                                                            },
                                                            "status": true,
                                                            "player_name": newData.name,
                                                            "odds": [
                                                                {
                                                                    "point": 0.5,
                                                                    "us": -115,
                                                                    "price": 1.87,
                                                                    "side": "1"
                                                                },
                                                                {
                                                                    "point": 0.5,
                                                                    "us": -115,
                                                                    "price": 1.87,
                                                                    "side": "2"
                                                                }
                                                            ]
                                                        }

                                                        const config = {
                                                            headers: {
                                                                Authorization: `Bearer ${this.props.accessToken}`,
                                                            },
                                                            };
                                                            var url =
                                                            "https://consoleproxy.sportsiq.ai/siqTools/punchPlayerLine?line=" +
                                                            JSON.stringify(shadingBody);
                                                            axios
                                                            .get(url, config)
                                                            .then((res) => {
                                                            })
                                                            .catch((error) => {
                                                                console.log("shading error");
                                                            });
                                                    }}
                                                    color="primary"
                                                >
                                                    {this.props.shading_market.split(", ")[2]}
                                                </Button>
                                                </div>:null
                                        }
                                        {rowData.starPlayer?
                                            <div style={{color: '#E4AE3A'}}  id={E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.STAR_PLAYER}>
                                                <StarIcon onClick={() => {
                                                    var oldData = JSON.parse(JSON.stringify(rowData));
                                                    var starPlayer = !rowData.starPlayer;
                                                    rowData.starPlayer = starPlayer;
                                                    var newData = rowData;
                                                    this.props.updateHandler(oldData, newData, false, this.props.title, this.props.game.status)
                                                }}/>
                                            </div>:
                                            <div style={{color: 'white'}} id={E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.NON_STAR_PLAYER}>
                                                <StarBorderIcon onClick={() => {
                                                    var oldData = JSON.parse(JSON.stringify(rowData));
                                                    var starPlayer = !rowData.starPlayer;
                                                    rowData.starPlayer = starPlayer;
                                                    var newData = rowData;
                                                    this.props.updateHandler(oldData, newData, false, this.props.title, this.props.game.status)
                                                }}/>
                                            </div>
                                        }
                                        <FormControl style={{marginRight: '15px'}}>
                                            <Select
                                                value={rowData.battingOrder}
                                                onChange={
                                                    (e) => {
                                                        var oldData = JSON.parse(JSON.stringify(rowData));
                                                        rowData.battingOrder = e.target.value;
                                                        rowData.changed = true;
                                                        var newData = rowData;
                                                        this.props.updateHandler(oldData, newData, false, this.props.title, this.props.game.status);
                                                        // props.action.onClick(e, rowData, props)
                                                        // props.action.onClick()
                                                    }
                                                }
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label', id: E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.BATTING_ORDER_DROPDOWN}}
                                            >
                                                <MenuItem value={99}><em>None</em></MenuItem>
                                                <MenuItem value={10}>Picked</MenuItem>
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                                <MenuItem value={6}>6</MenuItem>
                                                <MenuItem value={7}>7</MenuItem>
                                                <MenuItem value={8}>8</MenuItem>
                                                <MenuItem value={9}>9</MenuItem>
                                                <MenuItem style={{display:'none'}} value={100}><em>None</em></MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Switch inputProps={{id: E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.STARTING_PITCHER_SWITCH}}
                                            checked={rowData.startingPitcher}
                                            onChange={() => {
                                                var oldData = JSON.parse(JSON.stringify(rowData));
                                                var newData = JSON.parse(JSON.stringify(rowData));
                                                var startingPitcher = !newData.startingPitcher;
                                                if (startingPitcher === true) {
                                                    newData.battingOrder = 10;
                                                    newData.startingPitcher = true;
                                                } else if (startingPitcher === false) {
                                                    newData.battingOrder = 99;
                                                    newData.startingPitcher = false;
                                                }
                                                newData.changed = true;
                                                this.props.updateHandler(oldData, newData, true, this.props.title, this.props.game.status);
                                            }}
                                            color="primary"
                                            name="checkedB"
                                        />
                                    </div>
                                )
                            }
                        ),
                        {
                            icon: 'delete',
                            iconProps: {
                                color: "primary",
                                id: this.props.away ? E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.CLEAR_LINEUP_BUTTON : E2EASelectors.MAIN_PAGE.HOME_LINEUP_PANEL.CLEAR_LINEUP_BUTTON
                            },
                            tooltip: 'Clear Lineup',
                            isFreeAction: true,
                            onClick: () => {
                                this.props.clearGameLineup(this.props.game_id, this.props.team_id, this.props.away);
                            }
                        },
                        {
                            icon: 'refresh',
                            iconProps: {
                                color: "primary",
                                id: this.props.away ? E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.PULL_LINEUP_BUTTON : E2EASelectors.MAIN_PAGE.HOME_LINEUP_PANEL.PULL_LINEUP_BUTTON
                            },
                            tooltip: 'Pull Draft Lineup',
                            isFreeAction: true,
                            onClick: () => {
                                // this.props.pullLineup(this.props.team_id, this.props.away, this.props.selectedLeague);
                                this.props.pullLineup(this.props.game_id, this.props.team_id, this.props.away);
                            }
                        },
                        {
                            icon: 'save',
                            iconProps: {
                                color: "primary",
                                id: this.props.away ? E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.SAVE_LINEUP_BUTTON : E2EASelectors.MAIN_PAGE.HOME_LINEUP_PANEL.SAVE_LINEUP_BUTTON
                            },
                            tooltip: 'Save Lineup',
                            isFreeAction: true,
                            onClick: () => {
                                this.props.submitLineup(this.props.game_id, this.props.selectedLeague);
                            }
                        },
                        {
                            icon: 'publish',
                            iconProps: {
                                color: "primary",
                                id: this.props.away ? E2EASelectors.MAIN_PAGE.AWAY_LINEUP_PANEL.PUBLISH_LINEUP_BUTTON : E2EASelectors.MAIN_PAGE.HOME_LINEUP_PANEL.PUBLISH_LINEUP_BUTTON
                            },
                            tooltip: 'Publish Lineups',
                            isFreeAction: true,
                            onClick: () => {
                                var command = {};
                                command.command = "game_lineups_label";
                                command.game_id = this.props.game_id;
                                command.sr_game_id = this.props.game.sr_game_id;
                                command.status = 'revealed';
                                // this.props.sendCommand(command);
                                this.props.publishPostLineup(command);
                            }
                        },
                    ]
                }
            />
        );
    }
}