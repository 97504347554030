import React, { Component } from 'react';
import axios from 'axios';
import { Button, Select, FormControl, MenuItem } from '@material-ui/core';
import { db } from '.././firebase';

export default class TraderList extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            game_id: null,
            league: null,
            schedule: {},
            status: null
        }

        this.updateStatus = this.updateStatus.bind(this);
        this.StatusSelector = this.StatusSelector.bind(this);
        this.loadGameStatus = this.loadGameStatus.bind(this);
    }

    loadGameStatus() {
        this.setState({loading: true});
        this.setState({game_id: this.props.game_id})
        this.setState({league: this.props.selectedLeague})
        this.setState({status: null});
        var promise = db.collection("GAMEMANUALDAILYINFO").doc(this.props.selectedLeague.toLowerCase()+'-daily-info').get();
        promise.then(snapshot => {
            if (snapshot.data()) {
                var schedule = snapshot.data().schedule;
                var games = []
                if (schedule) {
                    this.setState({schedule: schedule});
                    games = JSON.parse(schedule).games;
                }
                games.forEach(game => {
                    if (game.id === this.props.game_id) {
                        this.setState({status: game.status})
                    }
                })
            }
        })
        .catch((error) => { // <---- you are missing this part
            console.log(error);     
        })
        this.setState({loading: false});
    }

    componentDidMount() {
        this.loadGameStatus();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.game_id !== this.props.game_id) {
            this.loadGameStatus();
        }
    }

    updateStatus() {
        var old_schedule = JSON.parse(this.state.schedule);
        if (old_schedule.games) {
            old_schedule.games.forEach(game => {
                if (game.id === this.state.game_id) {
                    game.status = this.state.status
                }
            })

            var manual_schedule = db.collection('GAMEMANUALDAILYINFO').doc(this.props.selectedLeague.toLowerCase()+'-daily-info');
            manual_schedule.set({
                schedule: JSON.stringify(old_schedule)
            })
        }
        // var newList = this.state.traders;
        // var oldList = this.state.traders;
        // var newEmailList = [];
        // newList.forEach(trader => {
        //     if (trader.email === event.target.name) {
        //         trader.checked = event.target.checked;
        //     }
        //     if (trader.checked === true) {
        //         newEmailList.push(trader.email)
        //     }
        // })
        // const document = db.collection("BOOKING_SIQ").doc(this.state.game_id);
        // var data = {
        //     emails: newEmailList
        // };
        // document.set(data);
        // this.setState({traders: newList});
    }

    StatusSelector(props) {
        var status_list = [
            'scheduled', 
            'postponed',
            'delayed',
            'cancelled',
            'TBD'
        ]
        return (
            <div>
                <div>
                    Game Status
                </div>
            <FormControl style={{marginRight: '15px'}}>
                <Select
                    value={this.state.status}
                    onChange={
                        (e) => {
                            this.setState({status: e.target.value})
                        }
                    }
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    {status_list.map(status => {
                        return (
                            <MenuItem key={status} value={status}>{status}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
            <Button onClick={()=>{
                this.updateStatus()
            }}
            color="primary">
                Update
            </Button>
            </div>
        )
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{margin: '0px 7px', height: '50px'}}>
                    Loading
                </div>
            )
        } else {
            return (
                <div style={{margin: '0px 7px'}}>
                    {this.state.status?
                        <div>
                            <this.StatusSelector />
                        </div>:
                        null
                    }
                </div>
            )
        }
    }
}