import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Avatar from '@material-ui/core/Avatar';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import TodayIcon from '@material-ui/icons/Today';
import Grow from '@material-ui/core/Grow';
// import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
// import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
// import SaveIcon from '@material-ui/icons/Save';
// import PrintIcon from '@material-ui/icons/Print';
// import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 380,
        transform: 'translateZ(0px)',
        flexGrow: 1,
    },
    speedDial: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    calendar: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));
  
// const actions = [
//     { icon: <FileCopyIcon />, name: 'Copy' },
//     { icon: <SaveIcon />, name: 'Save' },
//     { icon: <PrintIcon />, name: 'Print' },
//     { icon: <ShareIcon />, name: 'Share' },
//     { icon: <FavoriteIcon />, name: 'Like' },
// ];

function LineupsReady() {
    return(
        <div>
            <Avatar style={{backgroundColor: '#3f51b5'}}>
                <AssignmentTurnedInIcon />
            </Avatar>
        </div>
    )
}

function LineupsUnready() {
    return(
        <div>
            <Avatar>
                <AssignmentTurnedInIcon />
            </Avatar>
        </div>
    )
}
  
function OpenIconSpeedDial(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [checked, setChecked] = React.useState(props.lineups);

    const toggleChecked = () => {
      setChecked((prev) => !prev);
    };

    const handleOpen = () => {
        setChecked(props.lineups);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const updateLineupLable = (sendCommand, game) => {
        toggleChecked();
        var command = {};
        command.command = "game_lineups_label";
        command.game_id = game.id;
        command.status = !checked === true? 'revealed': 'missing';
        sendCommand(command);
    }

    return (
        <div>
            <SpeedDial
                ariaLabel="SpeedDial openIcon example"
                className={classes.speedDial}
                hidden={false}
                // icon={<SpeedDialIcon openIcon={<EditIcon />} />}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
            >
                {/* {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={handleClose}
                    />
                ))} */}
                <SpeedDialAction
                    key={'Lineups'}
                    icon={
                        checked ? 
                        <LineupsReady /> :
                        <LineupsUnready />
                    }
                    tooltipTitle={
                        checked? 
                            'Click to TAKE DOWN Lineup':
                            'Click to RELEASE Lineup'
                    }
                    onClick={()=>{updateLineupLable(props.sendCommand, props.game)}}
                />
            </SpeedDial>
        </div>
    );
}

export function CalendarPopup(props) {
    const classes = useStyles();
    return(
            <div className={classes.calendar} style={{cursor: 'pointer'}} onClick={()=>{props.showCalendar()}}>
                <Grow in={true}>
                    <Avatar className="calendarButton">
                        <TodayIcon style={{fontSize: '26px'}}/>
                    </Avatar>
                </Grow>
            </div>
    )
}

export default class LabelDial extends Component {

    render() {
        var lineups = false;
        var lineupsName = 'Missing'
        if (this.props.game) {
            lineups = this.props.game.labels.lineups === 'revealed'? true: false
            lineupsName = this.props.game.labels.lineups;
        }
        return (
            <div>
                <OpenIconSpeedDial game={this.props.game} lineups={lineups} lineupsName={lineupsName} sendCommand={this.props.sendCommand}/>
            </div>
        )
    }
}
