import React, { Component } from 'react'
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

var all_links = [
    {
        client: 'Sports IQ',
        links: [
            {
                env: 'SIQ Production',
                url: 'http://trading.sportsiq.ai/'
            },
            {
                env: 'SIQ Production Old',
                url: 'http://demo.sportsiq.ai/'
            },
            {
                env: 'SIQ Stage',
                url: 'http://stage.trading.sportsiq.ai/'
            },
            {
                env: 'SIQ Dev',
                url: 'https://reactdemodev.web.app/'
            },
            {
                env: 'SIQ Trial',
                url: 'https://trial.sportsiq.ai/'
            },
            {
                env: 'SIQ Unshaded',
                url: 'https://unshadeddemo.firebaseapp.com/'
            },
            {
                env: 'SIQ Trader',
                url: 'https://siq-trader.firebaseapp.com/'
            },
            {
                env: 'SIQ NBA',
                url: 'http://nba.sportsiq.ai/'
            },
        ]
    },
    {
        client: 'IGT',
        links: [
            {
                env: 'IGT Production',
                url: 'https://igt.trading.sportsiq.ai/'
            },
            {
                env: 'IGT Dev',
                url: 'https://igtdev.sportsiq.ai/'
            }
        ]
    },
    {
        client: 'Betclic',
        links: [
            {
                env: 'Betclic Admin Production',
                url: 'https://betclicadmin.trading.sportsiq.ai/'
            },
            {
                env: 'Betclic .COM Production',
                url: 'https://betclic.trading.sportsiq.ai/'
            },
            {
                env: 'Betclic .FR Production',
                url: 'https://betclicfr.trading.sportsiq.ai/'
            },
            {
                env: 'Betclic .PT Production',
                url: 'https://betclicpt.trading.sportsiq.ai/'
            },
            {
                env: 'Betclic .PL Production',
                url: 'https://betclicpl.trading.sportsiq.ai/'
            },
            {
                env: 'Betclic .COM Dev Old',
                url: 'https://betclicdev.sportsiq.ai/'
            },
            {
                env: 'Betclic .FR Dev Old',
                url: 'https://betclicdevfr.sportsiq.ai/'
            },
            {
                env: 'Betclic .PT Dev Old',
                url: 'https://betclicdevpt.sportsiq.ai/'
            },
            {
                env: 'Betclic .PL Dev Old',
                url: 'https://betclicdevpl.sportsiq.ai/'
            }
        ]
    },
    {
        client: 'Betworks',
        links: [
            {
                env: 'Betworks Production',
                url: 'https://betworks.trading.sportsiq.ai/'
            },
            {
                env: 'Betworks Production Old',
                url: 'https://betworks-siq.firebaseapp.com/'
            },
            {
                env: 'Betworks Dev Old',
                url: 'https://betworksdev-siq.firebaseapp.com/'
            }
        ]
    },
    {
        client: 'FSB',
        links: [
            {
                env: 'FSB Production',
                url: 'https://fsb-siq.firebaseapp.com/'
            }
        ]
    },
    {
        client: 'Betway',
        links: [
            {
                env: 'Betway Production',
                url: 'https://betway.trading.sportsiq.ai/'
            }
        ]
    }
]

export default class ConsoleLinks extends Component {
    constructor(props){
        super(props);
    
        this.state = {
            containWidth: Math.floor(window.innerWidth/360)*360+'px'
        }

        this.onresize = this.onresize.bind(this);
        this.LinkCollapse = this.LinkCollapse.bind(this);
    }

    onresize() {
        var width = document.body.clientWidth;
        var newContainWidth = Math.floor(width/360)*360+'px';
        this.setState({containWidth: newContainWidth});
    }

    LinkCollapse(props) {
        return (
            <div>
            {        
                props.links.map((link, index) => {
                    return (
                        <div key={index}>
                            <div >
                                <a href={link.url} target="_blank">{link.env}</a>
                            </div>
                        </div>
                    )
                })
            }
            </div>
        )

    }


    componentDidMount(){
        window.addEventListener("resize", this.onresize);
    }

    render() {
        return (
            <div style={{marginTop:'15px'}}>
                {all_links.map((client, index) => {
                    return (
                        <Accordion key={index}>
                            <Card className="cardStyle">
                                <Accordion.Toggle as={Card.Header} eventKey={index}>
                                    <button className="dropdownButton">
                                        {client.client}
                                    </button>
                                </Accordion.Toggle>
                                <this.LinkCollapse links={client.links}/>
                            </Card>
                        </Accordion>
                    )
                })}
            </div>
        )
    }
}
