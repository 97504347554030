import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
// import { DatePicker } from "react-bootstrap-date-picker";
import DatePicker from "react-datepicker";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'; 

export default class InjuryDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            date: new Date()
        }
        this.handleChange = this.handleChange.bind(this);

    }
    
    handleChange(newValue) {
        this.setState({date: newValue})
    };

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.dialogOpen}
                    onClose={()=>{this.props.closeDialog()}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {this.props.selectedPlayer.player_name} expected Return Date
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{height:'300px', overflow:true}}>
                        <DatePicker selected={this.state.date} onChange={this.handleChange} />
                        </div>
                        {/* By clicking "Save", you will {release} the {this.props.marketName} market. */}
                        {/* <DatePicker id="example-datepicker" value={this.state.date} onChange={this.handleChange} /> */}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>{this.props.closeDialog()}}  variant="secondary">
                        Cancel
                    </Button>
                    {!this.props.selectedPlayer.injury_id? 
                            <Button onClick={()=>{
                                this.props.addInjury(this.props.selectedPlayer.player_id, moment(this.state.date).format()); 
                                this.props.closeDialog();}} 
                                className="submitOdds" variant="primary">
                                Add
                            </Button> : 
                            <Button onClick={()=>{
                                this.props.updateInjury(this.props.selectedPlayer.injury_id, moment(this.state.date).format()); 
                                this.props.closeDialog(); 
                            }} 
                                className="submitOdds" variant="primary">
                                Update
                            </Button>
                    }

                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
