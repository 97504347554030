import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Switch from '@material-ui/core/Switch';
import StarIcon from '@material-ui/icons/Star';
import { Button, Select, FormControl, MenuItem } from "@material-ui/core";
import axios from "axios";
import StarBorderIcon from '@material-ui/icons/StarBorder';


export default class MLBLineupTable extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            columns: [
                { title: 'Name', field: 'name',
                cellStyle: {
                    width: 400,
                    maxWidth: 400
                  },
                  headerStyle: {
                    width:400,
                    maxWidth: 400
                  } 
                },
                { title: 'Position', field: 'position' },
                { title: 'SR ID', field: 'sr_player_id' },
                // { title: 'Team', field: 'team' },
                {
                    title: 'Order',
                    field: 'order',
                    defaultSort: 'asc',
                    hidden: true
                }
            ]
        }
    }


    componentDidMount() {

    }

    render() {
        return (
            <MaterialTable
                style={{margin:'10px 5px',  overflow: 'auto', backgroundColor:'#333333', color:'white'}}
                title={this.props.title}
                columns={this.state.columns}
                data={this.props.data}
                options = {
                    {
                        headerStyle:{backgroundColor:'#333333', color:'white'},
                        rowStyle: {color: 'white'},
                        actionsColumnIndex: -1,
                        search: this.props.selectedLeague === 'NFL',
                        padding: 'dense',
                        pageSize: 20,
                        pageSizeOptions: [20, 30],
                    }
                }
                localization={{
                    header: {
                        actions: 'Show – Release – Out'
                    }
                }}
                actions = {
                    [
                        rowData => (
                            {
                                icon: props => (
                                    <div style={{display:'flex'}}>
                                        {this.props.shading_market!==""?
                                            <div>
                                                <Button
                                                    onClick={() => {
                                                        var newData = rowData;
                                                        var shadingBody = {
                                                            "game_id": newData.gameId,
                                                            "email": this.props.email,
                                                            "note": this.props.shading_market.split(", ")[0],
                                                            "league": this.props.selectedLeague,
                                                            "player": {
                                                                "player_id": newData.playerId,
                                                                "team_id": this.props.team_id,
                                                            },
                                                            "market_id": Number(this.props.shading_market.split(", ")[1]),
                                                            "timestamp": new Date().toISOString(),
                                                            "period": 1,
                                                            "type": this.props.shading_market.split(", ")[2],
                                                            "live": false,
                                                            "bookmaker": {
                                                                "id": 999,
                                                                "name": "SIQ"
                                                            },
                                                            "status": true,
                                                            "player_name": newData.name,
                                                            "odds": [
                                                                {
                                                                    "point": 0.5,
                                                                    "us": -115,
                                                                    "price": 1.87,
                                                                    "side": "1"
                                                                },
                                                                {
                                                                    "point": 0.5,
                                                                    "us": -115,
                                                                    "price": 1.87,
                                                                    "side": "2"
                                                                }
                                                            ]
                                                        }

                                                        const config = {
                                                            headers: {
                                                                Authorization: `Bearer ${this.props.accessToken}`,
                                                            },
                                                            };
                                                            var url =
                                                            "https://consoleproxy.sportsiq.ai/siqTools/punchPlayerLine?line=" +
                                                            JSON.stringify(shadingBody);
                                                            axios
                                                            .get(url, config)
                                                            .then((res) => {
                                                            })
                                                            .catch((error) => {
                                                                console.log("shading error");
                                                            });
                                                    }}
                                                    color="primary"
                                                >
                                                    {this.props.shading_market.split(", ")[2]}
                                                </Button>
                                                </div>:null
                                        }
                                        {rowData.starPlayer?
                                            <div style={{color: '#E4AE3A'}}>
                                                <StarIcon onClick={() => {
                                                    var oldData = JSON.parse(JSON.stringify(rowData));
                                                    var starPlayer = !rowData.starPlayer;
                                                    rowData.starPlayer = starPlayer;
                                                    var newData = rowData;
                                                    this.props.updateHandler(oldData, newData, this.props.title, 'NHL', this.props.game.status)
                                                }}/>
                                            </div>:
                                            <div style={{color: 'white'}}>
                                                <StarBorderIcon onClick={() => {
                                                    var oldData = JSON.parse(JSON.stringify(rowData));
                                                    var starPlayer = !rowData.starPlayer;
                                                    rowData.starPlayer = starPlayer;
                                                    var newData = rowData;
                                                    this.props.updateHandler(oldData, newData, this.props.title, 'NHL', this.props.game.status)
                                                }}/>
                                            </div>
                                        }
                                        <Switch
                                            // disabled={!rowData.active}
                                            checked={rowData.active}
                                            onChange={() => {
                                                var oldData = JSON.parse(JSON.stringify(rowData));
                                                var active = !rowData.active;
                                                rowData.active = active;
                                                if (active === true) {
                                                    rowData.questionable = false;
                                                } else {
                                                    rowData.probable = false;
                                                }
                                                var newData = rowData;
                                                this.props.updateHandler(oldData, newData, this.props.title, 'NHL', this.props.game.status)
                                            }}
                                            color="primary"
                                            name="checkedB"
                                        />
                                        <Switch
                                            // disabled={!rowData.active}
                                            checked={rowData.probable}
                                            onChange={() => {
                                                var oldData = JSON.parse(JSON.stringify(rowData));
                                                var probable = !rowData.probable;
                                                rowData.probable = probable;
                                                if (probable === true) {
                                                    rowData.active = true;
                                                    rowData.questionable = false;
                                                }
                                                var newData = rowData;
                                                this.props.updateHandler(oldData, newData, this.props.title, 'NHL', this.props.game.status)
                                            }}
                                            color="primary"
                                            name="checkedB"
                                        />
                                        <Switch
                                            // disabled={!rowData.active}
                                            checked={rowData.questionable}
                                            onChange={() => {
                                                var oldData = JSON.parse(JSON.stringify(rowData));
                                                var questionable = !rowData.questionable;
                                                rowData.questionable = questionable;
                                                if (questionable === true) {
                                                    rowData.active = false;
                                                    rowData.probable = false;
                                                }
                                                var newData = rowData;
                                                this.props.updateHandler(oldData, newData, this.props.title, 'NHL', this.props.game.status)
                                            }}
                                            color="primary"
                                            name="checkedB"
                                        />
        
                                    </div>
                                )
                            }
                        ),
                        {
                            icon: 'delete',
                            iconProps: {
                                color: "primary"
                            },
                            tooltip: 'Clear Lineup',
                            isFreeAction: true,
                            onClick: () => {
                                this.props.clearGameLineup(this.props.game_id, this.props.team_id, this.props.away);
                            }
                        },
                        {
                            icon: 'refresh',
                            iconProps: {
                                color: "primary"
                            },
                            tooltip: 'Pull Lineup',
                            isFreeAction: true,
                            onClick: () => {
                                // if (this.props.selectedLeague === 'NHL' || this.props.selectedLeague === 'NFL' || this.props.selectedLeague === 'NBA' || this.props.selectedLeague === 'WNBA') {
                                //     this.props.pullLineup(this.props.team_id, this.props.away, this.props.selectedLeague);
                                // } else {
                                //     this.props.pullLineup(this.props.game_id, this.props.selectedLeague);
                                // }
                                this.props.pullLineup(this.props.game_id, this.props.team_id, this.props.away);
                            }
                        },
                        {
                            icon: 'save',
                            iconProps: {
                                color: "primary"
                            },
                            tooltip: 'Save Lineup',
                            isFreeAction: true,
                            onClick: () => {
                                this.props.submitLineup(this.props.game_id, this.props.selectedLeague);
                            }
                        },
                        {
                            icon: 'publish',
                            iconProps: {
                                color: "primary"
                            },
                            tooltip: 'Publish Lineups',
                            isFreeAction: true,
                            onClick: () => {
                                var command = {};
                                command.command = "game_lineups_label";
                                command.game_id = this.props.game_id;
                                command.sr_game_id = this.props.game.sr_game_id;
                                command.status = 'revealed';
                                // this.props.sendCommand(command);
                                this.props.publishPostLineup(command);
                            }
                        }
                    ]
                }
            />
        );
    }
}