import React, { Component } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Input,
} from "@material-ui/core";
import axios from "axios";

var slacks_channels = [];
var MY_SLACK_WEBHOOK_URLS = [
  // 'https://hooks.slack.com/services/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',
  "https://hooks.slack.com/services/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",
  // "https://hooks.slack.com/services/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",
  "https://hooks.slack.com/services/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",
  "https://hooks.slack.com/services/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",
  // "https://hooks.slack.com/services/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",
  // "https://hooks.slack.com/services/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",
  "https://hooks.slack.com/services/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",
  "https://hooks.slack.com/services/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",
  "https://hooks.slack.com/services/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",
  "https://hooks.slack.com/services/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",
  // "https://hooks.slack.com/services/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",
  "https://hooks.slack.com/services/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",
];
MY_SLACK_WEBHOOK_URLS.forEach((url) => {
  var slack = require("slack-notify")(url);
  slacks_channels.push(slack);
});

export default class BroadCaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      broadcastMsg: "",
      urgentReport: false,
      injury: false,
      pitcherChange: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    if (event.target.name === "Urgent Report") {
      this.setState({ urgentReport: !this.state.urgentReport });
    }
    if (event.target.name === "Injury Report") {
      this.setState({ injury: !this.state.injury });
      if (event.target.checked) {
        this.setState({ pitcherChange: false });
      }
    }
    if (event.target.name === "Pitcher Change") {
      this.setState({ pitcherChange: !this.state.pitcherChange });
      if (event.target.checked) {
        this.setState({ injury: false });
      }
    }
  }

  render() {
    // console.log(this.props);
    return (
      <div style={{ textAlign: "center" }}>
        <div>
          <FormControl
            style={{
              width: "50%",
              borderRadius: "6px",
              background: "black",
              paddingLeft: "10px",
              marginRight: "10px",
            }}
          >
            <InputLabel htmlFor="standard-adornment-amount">
              Broadcast to chnnels
            </InputLabel>
            <Input
              id="standard-adornment-amount"
              value={this.state.broadcastMsg}
              onChange={(e) => {
                this.setState({ broadcastMsg: e.target.value });
              }}
            />
          </FormControl>
          <Button
            style={{ marginTop: "7px" }}
            variant="contained"
            color="secondary"
            onClick={() => {
              var msg_templayer = "";
              var away_team = this.props.game.selectedGame.away.name;
              var home_team = this.props.game.selectedGame.home.name;
              msg_templayer =
                document.location.href.split("/")[4].substring(5) +
                " || " +
                this.props.game.selectedLeague +
                " – " +
                away_team +
                " vs. " +
                home_team +
                "\n";
              if (this.state.urgentReport) {
                msg_templayer +=
                  ":rotating_light: :rotating_light: :rotating_light: Urgent Report :rotating_light: :rotating_light: :rotating_light: " +
                  "\n";
              }
              if (this.state.injury) {
                msg_templayer +=
                  ":hospital: :hospital: :hospital: Injury Report :hospital: :hospital: :hospital: " +
                  "\n";
              }
              if (this.state.pitcherChange) {
                msg_templayer +=
                  ":baseball: :arrows_counterclockwise: :baseball: Pitching Change" +
                  "\n";
              }
              slacks_channels.forEach((channel) => {
                // channel.send(this.state.broadcastMsg);
                if (this.state.broadcastMsg.trim()) {
                  // console.log(msg_templayer.replaceAll(' ', '_').replaceAll('\n', '/n') + this.state.broadcastMsg);
                  channel.send(msg_templayer + this.state.broadcastMsg);
                }
              });
              //======================
              var url =
                "https://consoleproxy.sportsiq.ai/siqTools/publishToPubsub?comp_id=" +
                this.props.game.selectedGame.id +
                "&topic=console_popup&type=popup_message&source=dashboard&reload=false";
              url =
                url +
                "&league=" +
                this.props.game.selectedLeague +
                '&data={"data":"' +
                msg_templayer.replaceAll(" ", "_").replaceAll("\n", "/N") +
                this.state.broadcastMsg +
                '"}';
              if (this.state.broadcastMsg.trim()) {
                const config = {
                  headers: {
                    Authorization: `Bearer ${this.props.accessToken}`,
                  },
                };
                axios
                  .get(url, config)
                  .then((res) => {})
                  .catch((error) => {
                    console.log(error);
                  });
              }
              //======================
              this.setState({ broadcastMsg: "" });
              this.setState({ urgentReport: false });
              this.setState({ pitcherChange: false });
              this.setState({ injury: false });
            }}
          >
            Broadcast
          </Button>
        </div>
        <div>
          <FormControlLabel
            key={"Urgent Report"}
            control={
              <Checkbox
                checked={this.state.urgentReport}
                onChange={this.handleChange}
                name={"Urgent Report"}
              />
            }
            label={"Urgent Report"}
          />
          <FormControlLabel
            key={"Injury Report"}
            control={
              <Checkbox
                checked={this.state.injury}
                onChange={this.handleChange}
                name={"Injury Report"}
              />
            }
            label={"Injury Report"}
          />
          {this.props.game.selectedLeague === "MLB" ? (
            <FormControlLabel
              key={"Pitcher Change"}
              control={
                <Checkbox
                  checked={this.state.pitcherChange}
                  onChange={this.handleChange}
                  name={"Pitcher Change"}
                />
              }
              label={"Pitcher Change"}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
