import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import GameWidget from './GameWidget';
import { firstBy } from "thenby";

export default class GameAccordion extends Component {
    constructor(props){
        super(props);
    
        this.state = {
            containWidth: Math.floor(window.innerWidth/360)*360+'px'
        }

        this.onresize = this.onresize.bind(this);
        this.GameCollapse = this.GameCollapse.bind(this);
    }

    onresize() {
        var width = document.body.clientWidth;
        var newContainWidth = Math.floor(width/360)*360+'px';
        this.setState({containWidth: newContainWidth});
    }

    GameCollapse(props) {
        var good_games = [];
        props.games.forEach(game => {
            if (game.status !== 'unnecessary') {
                good_games.push(game);
            }
        })
        // console.log(good_games);
        good_games.sort(
            firstBy('scheduled')
            .thenBy('rotation_id')
        )
        // good_games.sort(function (a,b) {
        //     return ('' + a.id).localeCompare(b.id);
        // })
        // good_games.sort(function (a,b) {
        //     return new Date(a.scheduled).getTime() - new Date(b.scheduled).getTime();
        // })
        if (good_games.length > 0) {
            return (
                <Accordion.Collapse eventKey="0">
                    <div style={{width:this.state.containWidth}} className="gameWidgetContainer">
                        {good_games.map(game => 
                            <GameWidget key={game.id} game={game} sports={props.title}/>
                        )}
                    </div>
                </Accordion.Collapse>
            )
        } else {
            return (
                <Accordion.Collapse eventKey="0">
                    <div style={{textAlign:'center', marginTop:'10px', fontWeight:500, fontStyle:'italic'}}>
                        No Games Available
                    </div>
                </Accordion.Collapse>
            )
        }
    }

    componentDidMount(){
        window.addEventListener("resize", this.onresize);
    }

    render() {
        return (
            <div style={{marginTop:'15px'}}>
                <Accordion defaultActiveKey="0">
                    <Card className="cardStyle">
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            <button className="dropdownButton">
                                {this.props.title}
                            </button>
                        </Accordion.Toggle>
                        <this.GameCollapse games={this.props.games} title={this.props.title}/>
                    </Card>
                </Accordion>
            </div>
        )
    }
}
