import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyDUeXzZa7ikDdSdOcv8bSus9mRHYcn6cbg",
    authDomain: "lunar-brace-213823.firebaseapp.com",
    databaseURL: "https://lunar-brace-213823.firebaseio.com",
    projectId: "lunar-brace-213823",
    storageBucket: "lunar-brace-213823.appspot.com",
    messagingSenderId: "650996242589",
    appId: "1:650996242589:web:41e6831b63469e71",
    auth:'test'
});
const db = firebaseApp.firestore();

export { db }