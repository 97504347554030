import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import './okta-theme.css';

class SignInWidget extends Component {
  componentDidMount() {
    const el = ReactDOM.findDOMNode(this);

    var LOGO;
    if (process.env.REACT_APP_ENV === 'metric') {
      LOGO = 'Logo-metric.png';
    } else {
      LOGO = 'Logo-black-auth.png'
    }

    this.widget = new OktaSignIn({
      baseUrl: this.props.baseUrl,
      logo: LOGO
    });
    this.widget.renderEl({ el }, this.props.onSuccess, this.props.onError);
  }

  componentWillUnmount() {
    // this.widget.remove();
  }

  render() {
    return <div />;
  }
}

export default SignInWidget;