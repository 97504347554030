import React, { Component } from 'react'
import moment from 'moment-timezone';
import InfiniteCalendar from 'react-infinite-calendar';
import Grow from '@material-ui/core/Grow';

import 'react-infinite-calendar/styles.css'; // only needs to be imported once

function calendarPosition(){
    if (window.innerWidth < 400) {
        return {
            margin: 'auto',
            position: 'fixed',
            bottom: (window.innerHeight * 0.5-150).toString() + 'px',
            right: (window.innerWidth * 0.5-200).toString() + 'px'
        }
    } else {
        return {
            margin: 'auto',
            position: 'fixed',
            bottom: '40px',
            right: '40px'
        }
    }
}

export default class BasicDatePicker extends Component {

    render() {
        var today = moment(this.props.selectedDay).format();
        
        return (
            <div style={calendarPosition()}>
                <Grow in={true}>
                    <InfiniteCalendar
                        width={400}
                        height={300}
                        selected={today}
                        displayOptions={{
                            showHeader: false
                        }}
                        onSelect={(e)=>{
                            this.props.pickNewDate(moment(e).format('YYYY-MM-DD'));
                        }}
                    />
                </Grow>
            </div>
        )
    }
}
