import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { v4 as uuid } from 'uuid'; 

const useStyles = makeStyles({
  period_table: {
    minWidth: 300,
  },
});


function createAPIData(id, league_id, type) {
    return { id, league_id, type };
}

function General3ColumnsTable(threeColumnsRows){
    const classes = useStyles();
  
    return (
      <TableContainer id="league_table" component={Paper}>
        <Table className={classes.league_table} size="small" aria-label="a dense table">
          <TableBody>
            {threeColumnsRows.map(row => (
              <TableRow key={uuid()}>
                <TableCell style={{color: '#fff', borderColor:'#202020'}} component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell style={{color: '#fff', borderColor:'#202020'}} align="left">{row.league_id}</TableCell>
                <TableCell style={{color: '#fff', borderColor:'#202020'}} align="left">{row.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
}

function General2ColumnsTable(twoColumnsRows) {
    const classes = useStyles();
  
    return (
      <TableContainer id="periodTable" component={Paper}>
        <Table className={classes.period_table} size="small" aria-label="a dense table">
          <TableBody>
            {twoColumnsRows.map(row => (
              <TableRow key={uuid()}>
                <TableCell style={{color: '#fff', borderColor:'#202020'}} component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell style={{color: '#fff', borderColor:'#202020'}} align="left">{row.league_id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
}


export function GamePeriodTable() {
    const rows = [
        createAPIData('Source 1', 'Source 2'),
        createAPIData('Giannis Antetokounmpo', 'Giannis Antetokounmpo'),
        createAPIData('Giannis Antetokounmpo', 'Giannis Antetokounmpo'),
        createAPIData('Giannis Antetokounmpo', 'Giannis Antetokounmpo'),
        createAPIData('Giannis Antetokounmpo', 'Giannis Antetokounmpo'),
        createAPIData('Giannis Antetokounmpo', 'Giannis Antetokounmpo'),
    ];

    return General2ColumnsTable(rows)
}

export function TeamTable() {
    const rows = [
        createAPIData('Source 1', 'Source 2', 'Source 3'),
        createAPIData('Giannis Antetokounmpo', 'Giannis Antetokounmpo', 'Giannis Antetokounmpo'),
        createAPIData('Giannis Antetokounmpo', 'Giannis Antetokounmpo', 'Giannis Antetokounmpo'),
        createAPIData('Giannis Antetokounmpo', 'Giannis Antetokounmpo', 'Giannis Antetokounmpo'),
        createAPIData('Giannis Antetokounmpo', 'Giannis Antetokounmpo', 'Giannis Antetokounmpo'),
        createAPIData('Giannis Antetokounmpo', 'Giannis Antetokounmpo', 'Giannis Antetokounmpo'),
    ]
  
    return General3ColumnsTable(rows)
}