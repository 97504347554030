import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios';



export default class NFLRankingTable extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            columns: [
                { title: 'Name', field: 'name',
                cellStyle: {
                    width: 400,
                    maxWidth: 400
                  },
                  headerStyle: {
                    width:400,
                    maxWidth: 400
                  } 
                },
                // { title: 'Team', field: 'team' },
                {
                    title: 'Order',
                    field: 'order',
                    defaultSort: 'asc',
                    hidden: true
                },
                { title: 'Position', field: 'position' },
                { title: 'Ranking', field: 'ranking' },
                { title: 'All Rankings', field: 'all_rankings' },
            ]
        }
    }


    render() {
        return (
            <MaterialTable
                style={{margin:'10px 5px',  overflow: 'auto', backgroundColor:'#333333', color:'white'}}
                title={this.props.title}
                columns={this.state.columns}
                data={this.props.data}
                options = {
                    {
                        headerStyle:{backgroundColor:'#333333', color:'white'},
                        rowStyle: {color: 'white'},
                        actionsColumnIndex: -1,
                        search: this.props.selectedLeague === 'NFL',
                        padding: 'dense',
                        pageSize: 20,
                        pageSizeOptions: [20, 30],
                    }
                }
                actions = {
                    [
                        {
                            icon: 'refresh',
                            iconProps: {
                                color: "primary"
                            },
                            tooltip: 'Pull Rankings',
                            isFreeAction: true,
                            onClick: () => {
                                if (this.props.title === 'Away Rankings') {
                                    this.props.pullRankings(this.props.game_id, this.props.team_id, 'away');
                                }
                                if (this.props.title === 'Home Rankings') {
                                    this.props.pullRankings(this.props.game_id, this.props.team_id, 'home');
                                }
                            }
                        }
                    ]
                }
            />
        );
    }
}