import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import { ScaleLoader } from 'react-spinners';
import moment from 'moment-timezone';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Container, Button, FormControl, InputLabel, Input} from '@material-ui/core';
import './App.css';

import GameAccordion from './components/Accordion';
import BasicDatePicker from './components/DatePicker';
import ConsoleLinks from './components/ConsoleLinks';
import { CalendarPopup } from './components/LabelDial';
import * as E2EASelectors from './e2ea/e2ea_selectors.json'

var slacks_channels = []
var MY_SLACK_WEBHOOK_URLS = [
    // 'https://hooks.slack.com/services/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',
    'https://hooks.slack.com/services/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',
    // 'https://hooks.slack.com/services/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'
];
MY_SLACK_WEBHOOK_URLS.forEach(url => {
    var slack = require('slack-notify')(url);
    slacks_channels.push(slack);
})

export default withAuth (
    class Home extends Component {
        constructor(props) {
            super(props);

            this.state = {
                authenticated: null,
                tab: 'today',
                currentPage: 'bootstrap',
                datePicker: 'Date Picker',
                pickerBaseday: 2,
                lgShow: false,
                message: 'test',
                broadcastMsg:''
            }
            this.mainContent = this.mainContent.bind(this);
            this.handleTabChange = this.handleTabChange.bind(this);
            this.handleCalendarClose = this.handleCalendarClose.bind(this);
            this.pickNewDate = this.pickNewDate.bind(this);
        }

        login = async () => {
            this.props.auth.login('/');
        };

        logout = async () => {
            this.props.auth.logout('/');
        };

        handleCalendarClose() {
            this.setState({lgShow: false});
        }

        pickNewDate(day) {
            this.setState({datePicker: day});
            this.props.messageTrigger(this.state.currentPage, null, day);
        }
        
        handleTabChange(e) {
            var day;
            if (e === 'today') {
                // day = moment("2020-07-23").format('YYYY-MM-DD');
                day = moment(new Date()).format('YYYY-MM-DD')
                this.setState({lgShow: false});
            } else if (e === 'tomorrow') {
                day = moment(new Date()).add(1, 'days').format('YYYY-MM-DD');
                this.setState({lgShow: false});
            } else {
                if (this.state.datePicker === 'Date Picker') {
                    day = moment(new Date()).add(this.state.pickerBaseday, 'days').format('YYYY-MM-DD');
                    this.setState({datePicker: day});
                } else {
                    day = this.state.datePicker;
                }
            }
            if (!day) { 
                if (this.props.game.location.query) {
                    day = this.props.game.location.query.date
                }
            }
            this.props.messageTrigger(this.state.currentPage, null, day);
            this.setState({tab: e});
        }

        checkAuthentication = async () => {
            const authenticated = await this.props.auth.isAuthenticated();
            if (authenticated !== this.state.authenticated) {
                this.setState({
                    authenticated
                });
                var idToken = JSON.parse(localStorage.getItem('okta-token-storage'));
                var user_email;
                if (idToken && idToken.idToken){
                    user_email = idToken.idToken.claims.email;
                };
                this.props.authenticate(user_email);
                this.props.messageTrigger(this.state.currentPage, null, this.props.game.location.query?this.props.game.location.query.date: moment(new Date()).format('YYYY-MM-DD'));
                // this.props.messageTrigger(this.state.currentPage, null, this.props.game.location.query?this.props.game.location.query.date: moment(new Date()).format('YYYY-MM-DD'));
            }
        };

        async componentDidMount() {
            this.checkAuthentication();
            if (this.props.game.location.query) {
                if (this.props.game.location.query.date === moment(new Date()).format('YYYY-MM-DD')) {
                    this.setState({tab: 'today'});
                } else if (this.props.game.location.query.date === moment(new Date()).add(1, 'days').format('YYYY-MM-DD')) {
                    this.setState({tab: 'tomorrow'});
                } else {
                    this.setState({datePicker: this.props.game.location.query.date});
                    this.setState({tab: 'datePicker'});
                }
            }
        }

        async componentDidUpdate() {
            this.checkAuthentication();
        }

        mainContent = () => {
            if (this.state.authenticated) {
                    return (
                        <Container id={E2EASelectors.START_PAGE.GAMES_SELECTION_PANEL} maxWidth="xl" style={{marginTop:'50px', marginBottom:'50px'}}>
                            {/* {this.state.message} */}
                            {/* <button onClick={()=>{this.logout()}}>
                                LOGOUT
                            </button>
                            <button onClick={()=>{this.props.sendMessage(moment(new Date()).format('YYYY-MM-DD'))}}>
                                TEST
                            </button> */}
                            <div>
                            <FormControl style={{width: '50%', borderRadius: '6px', background:'black', paddingLeft:'10px', marginRight:'10px'}}>
                                <InputLabel htmlFor="standard-adornment-amount">Broadcast to channels</InputLabel>
                                <Input
                                    id="standard-adornment-amount"
                                    value={this.state.broadcastMsg}
                                    onChange={(e)=>{
                                        this.setState({broadcastMsg: e.target.value})
                                    }}
                                />
                            </FormControl>
                            <Button style={{marginTop:'7px'}} variant="contained" color="secondary" onClick={()=>{
                                slacks_channels.forEach(channel => {
                                    channel.bug(this.state.broadcastMsg);
                                })
                                this.setState({broadcastMsg:''})
                            }}>
                                Broadcast
                            </Button>
                            </div>
                            <Tabs className="homeTabStyle" activeKey={this.state.tab} id="uncontrolled-tab-example" onSelect={(e)=>this.handleTabChange(e)}>
                                <Tab eventKey="today" title="Today">
                                    {this.props.bootstrapLoading?
                                        <div className="spinnerStyle">
                                            <ScaleLoader color={'#FFBA38'} sizeUnit={"px"} size={70}/>
                                        </div>:
                                        <div>
                                            {this.props.bootstrap.map(league => 
                                                <GameAccordion key={league.league.id} title={league.league.name} games={league.games} />
                                            )}
                                        </div>
                                    }
                                </Tab>
                                <Tab eventKey="tomorrow" title="Tomorrow">
                                    {this.props.bootstrapLoading?
                                        <div className="spinnerStyle">
                                            <ScaleLoader color={'#FFBA38'} sizeUnit={"px"} size={70}/>
                                        </div>:
                                        <div>
                                            {this.props.bootstrap.map(league => 
                                                <GameAccordion key={league.league.id} title={league.league.name} games={league.games} />
                                            )}
                                        </div>
                                    }
                                </Tab>
                                <Tab eventKey="datePicker" title={this.state.datePicker}>
                                    {this.props.bootstrapLoading?
                                        <div className="spinnerStyle">
                                            <ScaleLoader color={'#FFBA38'} sizeUnit={"px"} size={70}/>
                                        </div>:
                                        <div>
                                            {this.props.bootstrap.map(league => 
                                                <GameAccordion key={league.league.id} title={league.league.name} games={league.games} />
                                            )}
                                        </div>
                                    }
                                </Tab>
                                <Tab eventKey="consoles" title="Consoles">
                                    {this.props.bootstrapLoading?
                                        <div className="spinnerStyle">
                                            <ScaleLoader color={'#FFBA38'} sizeUnit={"px"} size={70}/>
                                        </div>:
                                        <div>
                                            <ConsoleLinks />
                                        </div>
                                    }
                                </Tab>
                            </Tabs>
                            {this.state.tab === 'datePicker'?
                                <CalendarPopup showCalendar={()=>{
                                    var currentShow = this.state.lgShow;
                                    this.setState({lgShow: !currentShow});
                                }} /> : 
                                null
                            }
                            {this.state.lgShow?
                                <div>
                                    <BasicDatePicker handleCalendarClose={this.handleCalendarClose} pickNewDate={this.pickNewDate} selectedDay={this.state.datePicker}/>
                                </div> : 
                                null
                            }
                        </Container>
                    )
            } else {
                this.login();
            }
        }

        render() {
            if (this.state.authenticated === null) return null;

            return ( 
                <div style = {{textAlign: 'center'}} > 
                    {this.mainContent()} 
                </div>
            );
        }
    }
)
