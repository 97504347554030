import React, { Component } from 'react';
import axios from 'axios';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { db } from '.././firebase';

var traderList = [
    "Trial",
    "Ready to Release",
    "2nd Game of a Doubleheader"
]

function postToPuncher(data, comp_id, topic, type, source, reload, league){
    var email = 'default@sportsiq.ai';
    const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));
    if (idToken && idToken.idToken){
        email = idToken.idToken.claims.email;
    }

    var url = 'https://us-central1-lunar-brace-213823.cloudfunctions.net/sportsiq_http_proxy2/publishToPubsub?comp_id=' + comp_id + '&topic=' + topic + '&type=' + type + '&source=' + source + '&reload=' + reload;
    url = url + '&league=' + league + '&email=' + email + '&data=' + JSON.stringify(data);
    // console.log(url);

    axios.get(url)
        .then((res) => {
            if (res.status !== 200){
                data.error = res;
            }
        })
        .catch((error) => {
            data.error = error;
            console.log(error);
        })
}

export default class TraderList extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            traders:[],
            game_id: null,
            league: null
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({loading: true});
        this.setState({game_id: this.props.game_id})
        this.setState({league: this.props.selectedLeague})
        var trader_assignments = []
        var promise = db.collection("BOOKING_SIQ").doc(this.props.game_id).get();
        promise.then(snapshot => {
            // handle the document snapshot here
            if (snapshot.data()){
                var emails = snapshot.data().emails;
                traderList.forEach(trader => {
                    var assignment = {
                        email: trader,
                        checked: false
                    }
                    if (emails.includes(trader)) {
                        assignment.checked = true
                    }
                    trader_assignments.push(assignment);
                })
                this.setState({traders: trader_assignments})
            } else {
                traderList.forEach(trader => {
                    var assignment = {
                        email: trader,
                        checked: false
                    }
                    trader_assignments.push(assignment);
                })
                this.setState({traders: trader_assignments})
            }
            this.setState({loading: false})
        })
        .catch((error) => { // <---- you are missing this part
            console.log(error);     
        })

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.game_id !== this.props.game_id) {
            this.setState({loading: true});
            this.setState({game_id: this.props.game_id})
            this.setState({league: this.props.selectedLeague})
            var trader_assignments = []
            var promise = db.collection("BOOKING_SIQ").doc(this.props.game_id).get();
            promise.then(snapshot => {
                // handle the document snapshot here
                if (snapshot.data()){
                    var emails = snapshot.data().emails;
                    traderList.forEach(trader => {
                        var assignment = {
                            email: trader,
                            checked: false
                        }
                        if (emails.includes(trader)) {
                            assignment.checked = true
                        }
                        trader_assignments.push(assignment);
                    })
                    this.setState({traders: trader_assignments})
                } else {
                    traderList.forEach(trader => {
                        var assignment = {
                            email: trader,
                            checked: false
                        }
                        trader_assignments.push(assignment);
                    })
                    this.setState({traders: trader_assignments})
                }
                this.setState({loading: false})
            })
            .catch((error) => { // <---- you are missing this part
                console.log(error);     
            })
        }
    }

    handleChange(event) {
        var newList = this.state.traders;
        var oldList = this.state.traders;
        var newEmailList = [];
        newList.forEach(trader => {
            if (trader.email === event.target.name) {
                trader.checked = event.target.checked;
            }
            if (trader.checked === true) {
                newEmailList.push(trader.email)
            }
        })
        const document = db.collection("BOOKING_SIQ").doc(this.state.game_id);
        var data = {
            emails: newEmailList
        };
        document.set(data);
        this.setState({traders: newList});

        var url = 'https://us-central1-lunar-brace-213823.cloudfunctions.net/trader_booker?booked=' + event.target.checked + '&client=siq-traders'; 
        url += '&game_id=' + this.state.game_id + "&league=" + this.state.league + "&email=" + event.target.name + "&type=siq-traders";

        axios.get(url)
        .then(res => {
            var data = res.data;
            if (data.error) {
                this.setState({traders: oldList});
                console.log(data);
            }
        })
        .catch((error)=> {
            this.setState({traders: oldList});
        })

        if (event.target.name === '2nd Game of a Doubleheader') {
            var game_data = {
                game_id: this.state.game_id,
                second_game: event.target.checked
            }
            postToPuncher(game_data, this.state.game_id, 'siq.control', 'doubleheader_second', 'dashboard', false, this.state.league)
        }
    }



    render() {
        if (this.state.loading) {
            return (
                <div style={{margin: '0px 7px', height: '50px'}}>
                    Loading
                </div>
            )
        } else {
            return (
                <div style={{margin: '0px 7px'}}>
                    <div>
                        {this.state.traders.map((trader)=>{
                            // if (trader.email === "Ready to Release") {
                            //     return (
                            //         <div key={trader.email} style={{textAlign:'center', color:'#e4ae3a', fontWeight:'bold'}}>
                            //             <FormControlLabel
                            //                 key={trader.email}
                            //                 control={<Checkbox checked={trader.checked} onChange={this.handleChange} name={trader.email}/>}
                            //                 label={trader.email}
                            //             />
                            //         </div>
                            //     )
                            // }
                            // if (trader.email === "2nd Game of a Doubleheader") {
                            //     return (
                            //         <div key={trader.email} style={{textAlign:'center', color:'#e4ae3a', fontWeight:'bold'}}>
                            //             <FormControlLabel
                            //                 key={trader.email}
                            //                 control={<Checkbox checked={trader.checked} onChange={this.handleChange} name={trader.email}/>}
                            //                 label={trader.email}
                            //             />
                            //         </div>
                            //     )
                            // }
                        })}
                    </div>
                    <FormGroup row>
                        {this.state.traders.map((trader)=>{
                            if (trader.email !== "Ready to Release" && trader.email !== "2nd Game of a Doubleheader") {
                                return (
                                    <FormControlLabel
                                        key={trader.email}
                                        control={<Checkbox checked={trader.checked} onChange={this.handleChange} name={trader.email}/>}
                                        label={trader.email}
                                    />
                                )
                            }
                        })}
                    </FormGroup>
                </div>
            )
        }
    }
}
