import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { Security, SecureRoute, ImplicitCallback } from '@okta/okta-react';
import moment from 'moment-timezone';
import axios from 'axios';
import './App.css';

import Home from './Home';
import GameDetail from './GameDetail'
import Login from './components/auth/Login';
import { db } from './firebase';

import bootstap from './sampleJson/bootstrap.json';
import { Checkbox } from '@material-ui/core';

const config = {
    issuer: 'https://sportsiq.okta.com/oauth2/default',
    redirect_uri: window.location.origin + '/implicit/callback',
    client_id: process.env.REACT_APP_CLIENT_ID
}

function onAuthRequired({history}){
    history.push('/login');
}

var wsAddress;
var wsclient;
var client;

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: null,
            currentDay: null,
            game_id: null,
            user_email: null,
            loading: true, 
            bootstrapLoading: true,
            gamestrapLoading: true,
            bootstrapData: [],
            newmessage: 'nonooonooonoooMESSAGE'
        }

        this.messageTrigger = this.messageTrigger.bind(this);
        this.authenticate = this.authenticate.bind(this);
        this.connectToWs = this.connectToWs.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.sendCommand = this.sendCommand.bind(this);
    }

    messageTrigger(newPage, game_id, date){
        this.setState({currentPage: newPage});
        this.setState({game_id: game_id});
        this.setState({date: date});
        if (newPage === 'bootstrap') {
            this.setState({bootstrapLoading: true})
        }
        if (newPage === 'game_bootstrap') {
            this.setState({gamestrapLoading: true})
        }
        if (!this.state.loading) {
            this.sendMessage(this.state.currentPage, game_id, date);
        }
    }

    authenticate(email) {
        this.setState({user_email: email})
    }

    sendMessage(currentPage, game_id, date) {
        var command = {
            "command": currentPage,
            "game_id": game_id,
            "date": date,
            "timezone":moment().tz(moment.tz.guess()).format('z')
        }
        console.log('sending... ', JSON.stringify(command));
        wsclient.send(JSON.stringify(command));
    }

    sendCommand(command) {
        console.log('sending... ', JSON.stringify(command));
        var new_command = JSON.stringify(command);
        wsclient.send(new_command);
    }

    connectToWs() {
        // client = new W3CWebSocket('ws://104.198.216.190:8000');

        // client.onopen = () => {
        //     console.log('WebSocket Client Connected');
        // };
        // client.onmessage = (message) => {
        //     // console.log(message);
        //     console.log(message.data);
        //     // console.log(JSON.parse(message.data));
        //     // this.setState({message: JSON.parse(message.data).key})
        // };

        wsAddress = 'wss://dashboard.sportsiq.ai:8443/sportsiq-websocket/' + this.state.user_email;
        wsclient = new W3CWebSocket(wsAddress);
        wsclient.onopen = () => {
            this.setState({loading: false});
            if (this.state.currentPage !== 'bootstrap'){
                this.sendMessage('bootstrap', null, this.state.date);
            }
            this.sendMessage(this.state.currentPage, this.state.game_id, this.state.date);
        };
        wsclient.onmessage = (message) => {
            try {
                var data = JSON.parse(message.data);
                var newBootstrapData
                // console.log(JSON.stringify(data));
                console.log('received...', data);
                var chekList;
                var gameCheklist;
                if (data.type === 'bootstrap') {
                    this.setState({bootstrapData: data.data}); 
                    data.data.forEach(league => {
                        var totalGames = league.games.length;
                        var count = 0;
                        league.games.forEach(game => {
                            var game_id = game.id;
                            var promise = db.collection("DASHBOARD_CHECKLIST").doc(game_id).get();
                            promise.then(snapshot => {
                                // handle the document snapshot here
                                if (snapshot.data()){
                                    var data = snapshot.data();
                                    chekList = data.checkList;
                                    chekList.forEach(label => {
                                        game.labels[label.type] = label.checked;
                                    })
                                } else {
                                    chekList = [
                                        {
                                            "type": "rr",
                                            "checked": false
                                        },
                                        {
                                            "type": "vb",
                                            "checked": false
                                        },
                                        {
                                            "type": "sp",
                                            "checked": false
                                        },
                                        {
                                            "type": "bo",
                                            "checked": false
                                        },
                                        {
                                            "type": "sr",
                                            "checked": false
                                        },
                                        {
                                            "type": "cl",
                                            "checked": false
                                        },
                                        {
                                            "type": "to",
                                            "checked": false
                                        },
                                        {
                                            "type": "pp",
                                            "checked": false
                                        },
                                        {
                                            "type": "sc",
                                            "checked": false
                                        }
                                    ]
                                    chekList.forEach(label => {
                                        game.labels[label.type] = label.checked;
                                    })
                                }
                                
                            })
                            .then(() => {
                                // var url = 'https://us-central1-lunar-brace-213823.cloudfunctions.net/pull_siq_control_logger?game_id=' +  game.id;
                                // axios.get(url)
                                // .then(res => {
                                //     var logs = res.data.data;
                                //     game.control_logs = logs
                                // })
                                // .then(() => {
                                //     this.setState({bootstrapData: data.data});
                                //     count++;
                                //     if (count === totalGames) {
                                //         this.setState({bootstrapLoading: false});
                                //     }
                                // })
                                // .catch((error)=> {
                                //     console.log('siq control log pulling error')
                                // }) 
                                this.setState({bootstrapData: data.data});
                                count++;
                                if (count === totalGames) {
                                    this.setState({bootstrapLoading: false});
                                }
                            })
                            .catch((error) => { // <---- you are missing this part
                            console.log(error);     
                            })
                        })
                    })
                    this.setState({bootstrapLoading: false})
                }
                if (data.type === 'game_bootstrap') {
                    newBootstrapData = JSON.parse(JSON.stringify(this.state.bootstrapData));
                    newBootstrapData.forEach(league => {
                        league.games.forEach(game => {
                            if (game.id === data.game_id) {
                                game.details = data.data
                                var promise = db.collection("DASHBOARD_CHECKLIST").doc(game.id).get();
                                promise.then(snapshot => {
                                    // handle the document snapshot here
                                    if (snapshot.data()){
                                        var data = snapshot.data();
                                        gameCheklist = data.checkList;
                                        gameCheklist.forEach(label => {
                                            game.labels[label.type] = label.checked;
                                        })
                                    } else {
                                        gameCheklist = [
                                            {
                                                "type": "rr",
                                                "checked": false
                                            },
                                            {
                                                "type": "vb",
                                                "checked": false
                                            },
                                            {
                                                "type": "sp",
                                                "checked": false
                                            },
                                            {
                                                "type": "bo",
                                                "checked": false
                                            },
                                            {
                                                "type": "sr",
                                                "checked": false
                                            },
                                            {
                                                "type": "cl",
                                                "checked": false
                                            },
                                            {
                                                "type": "to",
                                                "checked": false
                                            },
                                            {
                                                "type": "pp",
                                                "checked": false
                                            },
                                            {
                                                "type": "sc",
                                                "checked": false
                                            }
                                        ]
                                        gameCheklist.forEach(label => {
                                            game.labels[label.type] = label.checked;
                                        })
                                    }
                                    
                                })
                                .then(() => {
                                    // var url = 'https://us-central1-lunar-brace-213823.cloudfunctions.net/pull_siq_control_logger?game_id=' +  game.id;
                                    // axios.get(url)
                                    // .then(res => {
                                    //     var logs = res.data.data;
                                    //     game.control_logs = logs
                                    // })
                                    // .then(() => {
                                    //     this.setState({bootstrapData:newBootstrapData});
                                    // })
                                    // .catch((error)=> {
                                    //     console.log('siq control log pulling error')
                                    // }) 
                                    this.setState({bootstrapData:newBootstrapData});
                                    this.setState({gamestrapLoading: false})
                                })
                                .catch((error) => { // <---- you are missing this part
                                console.log(error);     
                                })
                            }
                        })
                    })
                }
                if (data.type === 'game_labels_update') {
                    newBootstrapData = JSON.parse(JSON.stringify(this.state.bootstrapData));
                    newBootstrapData.forEach(league => {
                        league.games.forEach(game => {
                            if (game.id === data.game_id) {
                                game.labels = data.data.labels
                            }
                        })
                    })
                    this.setState({bootstrapData:newBootstrapData}); 
                }
            } catch (e) {
                console.log(message.data);
            }
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.user_email !== this.state.user_email && this.state.user_email) {
            this.connectToWs();
        }
    }

    render() {
        return (
            <Router>
                <Security issuer={config.issuer}
                  client_id={config.client_id}
                  redirect_uri={config.redirect_uri}
                  onAuthRequired={onAuthRequired}
                >
                    <div className="App">
                        <SecureRoute exact={true} path="/" render={(props) => (
                            <Home game={props} bootstrap={this.state.bootstrapData} sendMessage={this.sendMessage} authenticate={this.authenticate} messageTrigger={this.messageTrigger} loading={this.state.loading} bootstrapLoading={this.state.bootstrapLoading} />
                        )} />
                        <SecureRoute exact={true} path="/:sports/:date/game/:id" render={(props) => (
                            <GameDetail game={props} bootstrap={this.state.bootstrapData} sendMessage={this.sendMessage} sendCommand={this.sendCommand} authenticate={this.authenticate} messageTrigger={this.messageTrigger} loading={this.state.loading} bootstrapLoading={this.state.bootstrapLoading} gamestrapLoading={this.state.gamestrapLoading} message={this.state.newmessage} />
                        )} />
                        <Route path="/login" render={() => (<Login baseUrl="https://sportsiq.okta.com" />)}/>
                        <Route path="/implicit/callback" component={ImplicitCallback} />
                    </div>
                </Security>
            </Router>
        )
    }
}

export default App;