import React, { Component } from 'react'
import MaterialTable from 'material-table'
import { Button } from '@material-ui/core';
import moment from 'moment';
import axios from 'axios';
import InjuryDialog from './InjuryDialog';
import { db } from '../firebase';

export default class InjuryTables extends Component {
    constructor(props){
        super(props);

        this.state = {
            columns: [
                { title: 'Player', field: 'player_name', render: (row) => {
                    return (
                        <div className='injuryPlayerName' onClick={()=> {
                            this.setState({current_player: row})
                            this.setState({dialogOpen: true})
                        }}>
                            {row.player_name}
                        </div>
                    )
                }},
                // { title: 'Player', field: 'player_name' },
                { title: 'Return On', field: 'return_on' },
            ],
            home_injury:props.home_injury,
            away_injury:props.away_injury,
            email: 'default@sportsiq.ai',
            current_player: {},
            dialogOpen: false,
            accessToken: props.accessToken
        }

        this.Title = this.Title.bind(this);
        this.deleteInjury = this.deleteInjury.bind(this);
        this.addInjury = this.addInjury.bind(this);
        this.updateInjury = this.updateInjury.bind(this);
        this.pullInjury = this.pullInjury.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }
    
    closeDialog() {
        this.setState({dialogOpen: false});
    }

    pullInjury(game_id) {
        const config = {
            headers: {
              Authorization: `Bearer ${this.state.accessToken}`,
            },
        };
        var url = 'https://consoleproxy.sportsiq.ai/siqTools/injurySystem?method=get&game_id=' +  game_id;
        axios.get(url, config)
        .then(res => {
            var injuries = res.data.data;
            var away_injury = [];
            var home_injury = [];
            var total = injuries.length;
            var count = 0;
            injuries.forEach(player => {
                if (player.return_on) {
                    player.return_on = moment.utc(player.return_on).format('YYYY-MM-DD');
                }
                if (player.is_away) {
                    away_injury.push(player);
                } else {
                    home_injury.push(player);
                }
                count++;
                if (count === total) {
                    this.setState({away_injury: away_injury});
                    this.setState({home_injury: home_injury});
                }
            })
            
        })
        .catch((error)=> {
            console.log('siq control log pulling error')
        }) 
    }

    deleteInjury(player_id) {
        var injury_body = {
            player_id: player_id,
            email: this.state.email
        }
        const config = {
            headers: {
              Authorization: `Bearer ${this.state.accessToken}`,
            },
        };
        var url = 'https://consoleproxy.sportsiq.ai/siqTools/injurySystem?method=remove&data=' + JSON.stringify(injury_body);

        axios.get(url, config)
        .then(res => {
            if (res.status === 200) {
                this.pullInjury(this.props.game_id);
            }
        })
        .catch((error)=> {
            console.log('siq delete injury error')
        }) 
    }

    addInjury(player_id, return_on) {
        // var url = 'https://us-central1-lunar-brace-213823.cloudfunctions.net/add_new_injury?player_id=' + player_id + '&email=' + this.state.email + "&return_on=" + return_on + "&league=" + this.props.selectedLeague.toLowerCase();
        var injury_body = {
            player_id: player_id,
            email: this.state.email,
            return_on: return_on,
            league: this.props.selectedLeague.toLowerCase()
        }
        const config = {
            headers: {
              Authorization: `Bearer ${this.state.accessToken}`,
            },
        };
        var url = 'https://consoleproxy.sportsiq.ai/siqTools/injurySystem?method=add&data=' + JSON.stringify(injury_body);
        axios.get(url, config)
        .then(res => {
            if (res.status === 200) {
                this.pullInjury(this.props.game_id);
            }
        })
        .catch((error)=> {
            console.log('siq delete injury error')
        }) 
    }

    updateInjury(injury_id, return_on) {
        var injury_body = {
            injury_id: injury_id,
            email: this.state.email,
            return_on: return_on
        }
        const config = {
            headers: {
              Authorization: `Bearer ${this.state.accessToken}`,
            },
        };
        var url = 'https://consoleproxy.sportsiq.ai/siqTools/injurySystem?method=update&data=' + JSON.stringify(injury_body);
        axios.get(url, config)
        .then(res => {
            if (res.status === 200) {
                this.pullInjury(this.props.game_id);
            }
        })
        .catch((error)=> {
            console.log('siq delete injury error')
        }) 
    }

    Title(if_away) {
        return(
            <div style={{display:'flex'}}>
                {if_away?
                    <div style={{fontSize:'1.25rem'}}>
                        Away Injury Status
                    </div>:
                    <div style={{fontSize:'1.25rem'}}>
                        Home Injury Status
                    </div>
                }
            </div>
        )
    }

    componentDidMount() {
        var email = 'default@sportsiq.ai';
        const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));
        if (idToken && idToken.idToken){
            email = idToken.idToken.claims.email;
        }

        this.setState({email: email})
    }

    render() {
        return (
            <div>
            <div style={{display:'flex'}}>
                <div style={{width: '100%'}}>
                    <MaterialTable
                    style={{margin:'10px 5px',  overflow: 'auto', backgroundColor:'#333333', color:'white'}}
                    title={this.Title(true)}
                    columns={this.state.columns}
                    data={this.state.away_injury}
                    options = {
                        {
                            headerStyle:{backgroundColor:'#333333', color:'white'},
                            rowStyle: {color: 'white'},
                            search: this.props.selectedLeague === 'NFL',
                            padding: 'dense',
                            pageSize: 20,
                            pageSizeOptions: [20, 30],
                        }
                    }
                    actions = {
                        [
                            {
                                icon: 'refresh',
                                iconProps: {
                                    color: "primary"
                                },
                                tooltip: 'Pull Lineup',
                                isFreeAction: true,
                                onClick: () => {
                                    this.pullInjury(this.props.game_id);
                                }
                            },
                            rowData => (
                                {
                                    icon: 'delete',
                                    iconProps: {
                                        color: "secondary"
                                    },
                                    tooltip: 'Remove Injury',
                                    hidden: rowData.return_on? false:true,
                                    onClick: (event, rowData) => this.deleteInjury(rowData.player_id)
                                    // disabled: rowData.return_on? false:true,
                                    // onClick: (event, rowData) => alert("You want to delete " + rowData.name)
                                }
                            )
                        ]
                    }
                />
                </div>
                <div style={{width: '100%'}}>
                    <MaterialTable
                    style={{margin:'10px 5px',  overflow: 'auto', backgroundColor:'#333333', color:'white'}}
                    title={this.Title(false)}
                    columns={this.state.columns}
                    data={this.state.home_injury}
                    options = {
                        {
                            headerStyle:{backgroundColor:'#333333', color:'white'},
                            rowStyle: {color: 'white'},
                            search: this.props.selectedLeague === 'NFL',
                            padding: 'dense',
                            pageSize: 20,
                            pageSizeOptions: [20, 30],
                        }
                    }
                    actions = {
                        [
                            {
                                icon: 'refresh',
                                iconProps: {
                                    color: "primary"
                                },
                                tooltip: 'Pull Lineup',
                                isFreeAction: true,
                                onClick: () => {
                                    this.pullInjury(this.props.game_id);
                                }
                            },
                            rowData => (
                                {
                                    icon: 'delete',
                                    iconProps: {
                                        color: "secondary"
                                    },
                                    hidden: rowData.return_on? false:true,
                                    tooltip: 'Remove Injury',
                                    onClick: (event, rowData) => this.deleteInjury(rowData.player_id)
                                }
                            ),
                            // rowData => (
                            //     { 
                            //         icon: 'save',
                            //         iconProps: {
                            //             color: "primary"
                            //         },
                            //         tooltip: 'Update Injury',
                            //         onClick: (event, rowData) => console.log(rowData)
                            //     }
                            // )
                        ]
                    }
                />
                </div>
            </div>
            {this.state.dialogOpen?
                <InjuryDialog email={this.state.email} dialogOpen={this.state.dialogOpen} selectedGame={this.props.selectedGame} selectedPlayer={this.state.current_player} addInjury={this.addInjury} updateInjury={this.updateInjury} closeDialog={this.closeDialog}/>
                :null
            }
            </div>
        )
    }
}
