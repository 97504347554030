import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Switch from '@material-ui/core/Switch';
import { db } from '.././firebase';

export default class CheckList extends Component {
    constructor(props){
        super(props);

        this.state = {
            columns: [
                { title: 'Tasks', field: 'task' },
                { title: 'Assignee',
                  field: 'assigned',
                },
                { title: 'Deadline',
                  field: 'deadline',
                },
                { title: 'Escalation',
                  field: 'escalation',
                },
            ]
        }

        this.Title = this.Title.bind(this);
    }

    Title(game_id) {
        return(
            <div style={{display:'flex'}}>
                <div style={{fontSize:'1.25rem'}}>
                    Pre-Match Checklist
                </div>
                <div style={{marginLeft:'10px', marginTop:'6px'}}>
                    <a href={"https://demo.sportsiq.ai/mlb/game/"+game_id+"/team"} target="_blank">Demo Link</a>
                </div>
            </div>
        )
    }

    componentDidMount() {
        var bootData = [
            {
                "task": "Ready to release",
                "type": "rr",
                "assigned": "Joe",
                "deadline": "",
                "escalation": "",
                "checked": this.props.selectedGame.labels.rr
            },
            {
                "task": "Lines verified",
                "type": "vb",
                "assigned": "Brady",
                "deadline": "",
                "escalation": "",
                "checked": this.props.selectedGame.labels.vb
            },
            // {
            //     "task": "Closing Line Inputted",
            //     "type": "cl",
            //     "assigned": "Laurent",
            //     "deadline": "As soon as numbers have been realeased from other bookmakers.",
            //     "escalation": "Page Matt / Heden if closing lines cannot be entered through Demo's Puncher.",
            //     "checked": this.props.selectedGame.labels.cl
            // },
            // {
            //     "task": "Stats Ready & Checked",
            //     "type": "sr",
            //     "assigned": "George / Henry",
            //     "deadline": "As soon as Pitchers have been inputted/changed.",
            //     "escalation": "Page Matt to rerun the model if stats recalculates.",
            //     "checked": this.props.selectedGame.labels.sr
            // },
            // {
            //     "task": "Team Odds Released",
            //     "type": "to",
            //     "assigned": "Joe / Laurent",
            //     "deadline": "As soon as Stats are generated/checked",
            //     "escalation": "Check on the Demo site, page #gamemonitor if the first three steps are ready but Team Odds are still missing.",
            //     "checked": this.props.selectedGame.labels.to
            // },
            // {
            //     "task": "Player Props Released",
            //     "type": "pp",
            //     "assigned": "Joe / Laurent",
            //     "deadline": "As soon as Stats are generated/checked",
            //     "escalation": "Check on the Demo site, page #gamemonitor if the first three steps are ready but Player Props are still missing",
            //     "checked": this.props.selectedGame.labels.pp
            // },
            // {
            //     "task": "Compare with other bookmakers",
            //     "type": "sc",
            //     "assigned": "Joe / Laurent",
            //     "deadline": "Right after odds have been released.",
            //     "escalation": "Page #gamemonitor, and take down markets if lines are too far off.",
            //     "checked": this.props.selectedGame.labels.sc
            // }
        ]
        this.setState({data: bootData});
    }

    render() {

        return (
            <div>
                <MaterialTable
                style={{margin:'10px 5px',  overflow: 'auto', backgroundColor:'#333333', color:'white'}}
                title={this.Title(this.props.game_id)}
                columns={this.state.columns}
                data={this.state.data}
                options = {
                    {
                        headerStyle:{backgroundColor:'#333333', color:'white'},
                        rowStyle: {color: 'white'},
                        search: false,
                        paging: false,
                        actionsColumnIndex: -1,
                        padding: 'dense'
                    }
                }
                actions = {
                    [
                        rowData => (
                            {
                                icon: props => (
                                    <div style={{display:'flex'}}>
                                        <Switch
                                            checked={rowData.checked}
                                            onChange={() => {
                                                if (rowData.type !== "lineups") {
                                                    var newData = JSON.parse(JSON.stringify(this.state.data));
                                                    newData.forEach(label => {
                                                        if (label.type === rowData.type) {
                                                            var newStatus = !label.checked;
                                                            label.checked = newStatus;
                                                        }
                                                    });
                                                    this.setState({data: newData}, function(){
                                                        var newDataStoreData = JSON.parse(JSON.stringify(this.state.data));
                                                        newDataStoreData = newDataStoreData.filter(label => !(label.type === 'lineups'));
                                                        newDataStoreData.forEach(label => {
                                                            delete label.assigned;
                                                            delete label.escalation;
                                                            delete label.deadline;
                                                            delete label.tableData;
                                                            delete label.task;
                                                        })
                                                        const document = db.collection('DASHBOARD_CHECKLIST').doc(this.props.game_id);
                                                        var oddData = {
                                                            checkList: newDataStoreData
                                                        };
                                                        document.set(oddData);
                                                    });
                                                }
                                            }}
                                            color="primary"
                                            name="checkedB"
                                        />
                                    </div>
                                )
                            }
                        )
                    ]
                }
            />
            </div>
        )
    }
}
